// 生成一个随机数 传入一个数字 返回该数字上下浮动1%的随机数
export function randomNumber(number) {
    // 生成一个0-0.01的随机数
    let random = Math.random() * 0.01;
    // flag 为正数或者负数
    let flag = Math.random() > 0.5 ? 1 : -1;
    return number + number * random * flag;
}

// 缺省走势图的数据
export function randomLine() {
    const initialPrice=52100;
    // 生成100个随机数，模拟价格的变动，使得趋势相对平缓
    const randomChanges=Array.from({ length: 99 }, () => (Math.random()-0.5)*10);
    // 计算每个价格点
    const prices=randomChanges.reduce((acc, randomChange) => {
        const lastPrice=acc[acc.length-1];
        const newPrice=lastPrice+randomChange
        return [...acc, newPrice];
    }, [initialPrice]);
    return prices
}