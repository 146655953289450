<template>
  <div class="home">
    <!-- <div v-if="innerWidth > 1000"> -->
      <HomePc></HomePc>
    <!-- </div> -->
    <!-- <div v-else>
      <HomeM></HomeM>
    </div> -->
  </div>
</template>

<script>
// import {editUserPwd} from "@/api/user";
// @ is an alias to /src
import HomePc from "./components/home_pc.vue";
import HomeM from "./components/home_m.vue";
import { mapState } from "vuex";
export default {
  name: "HomeView",
  data() {
    return {};
  },
  mounted() {
    // editUserPwd().then((res) => {
    //   console.log(res);
    // });
  },
  computed: {
    ...mapState(["innerWidth"]),
  },
  components: {
    HomePc,
    HomeM,
  },
};
</script>
