import request from '@/utils/request'
// 用户认证
export function userAuth(data) {
    return request({
        url: '/swx/customer/user/identification',
        method: 'post',
        data
    })
}
// 用户信息
export function getUserInfo(data) {
    return request({
        url: '/swx/customer/user/getUserInfo',
        method: 'post',
        data
    })
}
// recharge
export function recharge(data) {
    return request({
        url: '/swx/customer/user/recharge',
        method: 'post',
        data
    })
}
// 提现
// withdrawal
export function withdrawal(data) {
    return request({
        url: '/swx/customer/user/withdrawal',
        method: 'post',
        data
    })
}

// buyNft
export function buyNft(data) {
    return request({
        url: `/swx/customer/user/buyNft`,
        method: 'post',
        data
    })
}
// listNft
export function userListNft(param) {
    return request({
        url: `/swx/customer/user/listNft`,
        method: 'get',
        params: {
            ...param
        }
    })
}
// loan
export function loan(data) {
    return request({
        url: `/swx/customer/user/loan`,
        method: 'post',
        data
    })
}

// listRecharge 充值订单
export function listRecharge(param) {
    return request({
        url: `/swx/customer/user/listRecharge`,
        method: 'get',
        params: {
            ...param
        }
    })
}
// listWithdrwal 提现订单
export function listWithdrwal(param) {
    return request({
        url: `/swx/customer/user/listWithdrwal`,
        method: 'get',
        params: {
            ...param
        }
    })
}
// listLoanRecord 借贷订单
export function listLoanRecord(param) {
    return request({
        url: `/swx/customer/user/listLoanRecord`,
        method: 'get',
        params: {
            ...param
        }
    })
}
// /swx/customer/user/repassword
export function repassword(data) {
    return request({
        url: `/swx/customer/user/repassword`,
        method: 'post',
        data
    })
}