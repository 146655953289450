<template>
	<div class="language-main" :class="!isPc?'language-main-mobile' :''" v-show="isLanguage" @click.stop="closeModal()">
		<div class="content" @click.stop="">
			<div class="title">{{ $t('account.changeLanguage') }}</div>
			<div class="content-box">
				<div class="box " :class="{ 'box-active': langIndex == index }" @click="langChange(index)"
					v-for="(item, index) in langList" :key="index">{{ item.name }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		name: 'Languages',
		data() {
		  return {
			
		  }
		},
		props:{
			isPc:{
				type:Boolean,
				default:false
			},
			isLanguage:{
				type:Boolean,
				default:false
			}
		},
		computed: {
		  ...mapGetters(["langList", "langIndex"]),
		},
		methods: {
			langChange(index) {
				// this.langIndex=index;
				// this.$store.commit('SET_LANG', this.langList[index].value);
			
				let lang = this.langList[index].value;
				this.closeModal();
				this.$store.commit('SET_LANG', lang);
				this.$store.dispatch('listContents', lang == 'zh' ? 1 : 2);
			},
			closeModal() {
				this.$emit('close');
			},
		},
	}
</script>

<style>
	
	.language-main {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, .4);
		display: flex;
		justify-items: center;
		align-items: center;
		justify-content: center
	}
	
	.language-main .content {
		width: 4.32rem;
		background: #000;
		border-radius: .16rem;
		padding: .24rem .32rem;
		color: #fff;
		text-align: center
	}
	
	.language-main .content .title {
		font-weight: 500;
		font-size: .2rem;
		margin-bottom: .18rem
	}
	
	.language-main .content .content-box {
		cursor: pointer;
		width: 3.16rem;
		margin: auto
	}
	
	.language-main .content .content-box .box {
		width: 3.16rem;
		height: .46rem;
		border-radius: .24rem;
		line-height: .46rem;
		margin-bottom: .16rem
	}
	
	.language-main .content .content-box .box-active {
		background: #fff;
		color: #000
	}
	
	.language-main-mobile {
		z-index: 11
	}
	
	.language-main-mobile .content {
		max-height: 80%;
		overflow: auto
	}
</style>