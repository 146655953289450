<template>
	<div>
		<nav class="nav_mobile_top topPadding">
			<div class="nav_content">
				<div class="nav_left">
					<div class="back" v-if="isShowBack" @click="$emit('back')"><img
							src="@/assets/img/icon_back.b2094a45.svg" alt=""></div>
					<div class="logo"><img src="@/assets/img/sax_logo.png" alt="">
					</div>
					<div class="nav_name">{{ title }}</div>
				</div>
				<div class="nav_right" v-if="showRight">
					<div class="action" @click="isLanguage = true" style="    width: auto;  border-radius: 5px;  padding: 0 5px;">{{$t('navTab.language')}}</div>
					<div class="action sign" v-if="isLogin" @click="$router.push('/profile/notification')"><img
							src="@/assets/img/icon_bell.28bcd54a.svg" alt=""></div>
					<div class="action sign" @click="openService">
						<img src="@/assets/img/service-m.svg" :alt="$t('navBottom.bottomCompanyItem4')">
					</div>
					<div class="action btn sign" @click="$router.push('/pass/login')" v-if="!isLogin">
						{{ $t('login.btnLogin') }}
					</div>
				</div>
			</div>


			<!-- <div class="search-main" v-show="false">
        <div class="content" id="content">
          <div class="search-input"><img src="@/assets/img/icon_search.9fcf95f5.svg" alt=""><input type="text"
              :placeholder="$t('souSuo')"></div>
          <div class="hr"></div>
          <div class="search-reault">
            <div>
              <div class="result-list">
                <div class="list-title"><img src="/images/a1af7fed02f7f6d65526b7208aaab0d2.png" alt=""> Apple Inc</div>
                <div class="list-number">$191.2418</div>
                <div class="list-cent">0.48% </div>
              </div>
            </div>
            <div>{{ $t('toast.nothingfound') }}</div>
          </div>
        </div>
      </div> -->


		</nav>
		<Languages :isPc="isPc" :isLanguage="isLanguage" @close="isLanguage=false" />
	</div>
</template>
<script>
	import {
		mapState
	} from "vuex";
	import Languages from "@/components/Languages.vue";
	export default {
		name: "HomeView",
		data() {
			return {
				isLanguage: false,
			};
		},
		props: {
			title: {
				type: String,
				default: "GD",
			},
			showRight: {
				type: Boolean,
				default: true,
			},
			isShowBack: {
				type: Boolean,
				default: false,
			},

		},
		computed: {
			...mapState(["isLogin","isPc"]),
			getTitle() {
				return this.$route.meta.title;
			},
		},
		components: {
			Languages
		},
		methods: {
			openService() {
				let origin = window.location.origin;
				window.open(origin + '/service.html?language=en');
			}
		},
	};
</script>
<style>
	.nav_mobile_top {
		position: fixed;
		top: 0;
		left: 0;
		/* height: 0.36rem; */
		width: 100%;
		background: #fff;
		padding: 0.22rem 0;
		z-index: 11;
	}

	.nav_mobile_top.topPadding {
		padding: 0.44rem 0 0.1rem 0;
	}

	.nav_mobile_top .nav_content {
		padding: 0 0.24rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.nav_mobile_top .nav_content .nav_left {
		display: flex;
		align-items: center;
	}

	.nav_mobile_top .nav_content .nav_left .back {
		margin-right: 0.1rem;
		width: 0.36rem;
		height: 0.36rem;
		box-sizing: border-box;
		border-radius: 50%;
		border: 0.02rem solid #000;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.nav_mobile_top .nav_content .nav_left .logo img {
		/* border-radius: 50%; */
	}

	.nav_mobile_top .nav_content .nav_left .back img {
		width: 0.16rem;
		height: auto;
	}

	.nav_mobile_top .nav_content .nav_left .logo {
		margin-right: 0.08rem;
		height: 0.36rem;
	}

	.nav_mobile_top .nav_content .nav_left .logo img {
		height: 100%;
		width: auto;
	}

	.nav_mobile_top .nav_content .nav_left .nav_name {
		/* padding-bottom: 0.04rem; */
		font-weight: 700;
		font-size: 0.22rem;
		color: #000;
	}

	.nav_mobile_top .nav_content .nav_right {
		display: flex;
	}

	.nav_mobile_top .nav_content .nav_right .action {
		margin-left: 0.12rem;
		width: 0.36rem;
		height: 0.36rem;
		border: 0.02rem solid #000;
		box-sizing: border-box;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.nav_mobile_top .nav_content .nav_right .action.btn {
		width: auto;
		border: none;
		font-weight: 700;
		font-size: 0.22rem;
		color: #000;
	}

	.nav_mobile_top .nav_content .nav_right .action.sign {
		position: relative;
		margin-left: 0.1rem;
	}

	.nav_mobile_top .nav_content .nav_right .action.sign .dot {
		position: absolute;
		top: -0.02rem;
		right: 0;
		width: 0.1rem;
		height: 0.1rem;
		border-radius: 50%;
		background: #ff5858;
	}


	.search-main {
		width: 100%;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, .6);
		z-index: 12;
		line-height: 100%;
		overflow-y: auto
	}

	.search-main .content {
		background: #fff;
		border-radius: .16rem;
		width: 3.8rem;
		min-height: 2.62rem;
		margin: auto;
		margin-top: .62rem;
		padding-top: .24rem;
		padding-bottom: .16rem;
		margin-bottom: .9rem
	}

	.search-main .content .search-input {
		height: .26rem;
		margin: auto;
		margin-bottom: .1rem;
		width: 3.5rem
	}

	.search-main .content .search-input input {
		width: 3.1rem
	}

	.search-main .content .search-input img {
		vertical-align: middle
	}

	.search-main .content .hr {
		width: 3.48rem;
		height: .01rem;
		border-bottom: .01rem solid rgba(0, 0, 0, .1);
		margin: auto;
		margin-bottom: .14rem
	}

	.search-main .content .search-reault {
		width: 3.5rem;
		margin: auto;
		text-align: center;
		min-height: 2rem;
		line-height: 2rem;
		font-weight: 400;
		font-size: .2rem
	}

	.search-main .content .search-reault .result-list {
		width: 100%;
		height: .58rem;
		padding: 0 .23rem 0 .16rem;
		line-height: .58rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: .04rem;
		background: #000;
		border-radius: .16rem
	}

	.search-main .content .search-reault .result-list .list-title {
		font-weight: 500;
		font-size: .12rem;
		color: #fff;
		line-height: .58rem
	}

	.search-main .content .search-reault .result-list .list-title img {
		margin-right: .08rem;
		height: .22rem;
		vertical-align: middle
	}

	.search-main .content .search-reault .result-list .list-number {
		font-weight: 400;
		font-size: .12rem;
		color: hsla(0, 0%, 100%, .5);
		line-height: .58rem
	}

	.search-main .content .search-reault .result-list .list-cent {
		font-weight: 400;
		font-size: .12rem;
		color: #9ee970;
		line-height: .58rem
	}

	.search-main .content .search-reault .result-list .list-cent1 {
		color: #ff2828
	}
</style>