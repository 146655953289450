import request from '@/utils/request'

// 检测用户名邮箱
export function checkUserNameOrPhone(params) {
    return request({
        url: '/swx/customer/login/checkUserNameOrPhone',
        method: 'get',
        params: {
            ...params
        }
    })
}

// 注册
export function register(data) {
    return request({
        url: '/swx/customer/login/register',
        method: 'post',
        data
    })
}

// 登录
export function login(data) {
    return request({
        url: '/swx/customer/login/login',
        method: 'post',
        data
    })
}

// sendCode
export function sendCode(data) {
    return request({
        url: `/swx/customer/login/sendCode`,
        method: 'post',
        data
    })
}
// loginByGoogle
export function loginByGoogle(data) {
    return request({
        url: `/swx/customer/login/loginByGoogle`,
        method: 'post',
        data
    })
}









// exmaple  qs

// import qs from 'qs';
// export function memberintegralflowList(data = {}) {
//   return request({
//     url: '/user/member-integral-flow-do/search?' + qs.stringify(data),
//     method: 'get'
//   });
// }