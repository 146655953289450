import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/market',
    name: 'market',
    component: () => import('../views/market/Market.vue')
  },
  {
    path: '/trading',
    name: 'trading',
    component: () => import('../views/trading/Trading.vue')
  },
  {
    path: '/loan',
    name: 'loan',
    component: () => import('../views/loan/Loan.vue')
  },
  {
    path: '/nfts',
    name: 'nfts',
    component: () => import('../views/nfts/Nfts.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/profile/Profile.vue')
  },
  {
    path: '/cruuency',
    name: 'cruuency',
    component: () => import('../views/cruuency/Cruuency.vue')
  },
  {
    path: '/profile/balance',
    name: 'balance',
    component: () => import('../views/profile/balance/index.vue')
  },
  {
    path: '/profile/invite',
    name: 'invite',
    component: () => import('../views/profile/invite/index.vue')
  },
  {
    path: '/profile/notification',
    name: 'notification',
    component: () => import('../views/profile/notification/index.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/help/index.vue')
  },
  {
    path: '/help/faq',
    name: 'faq',
    component: () => import('../views/help/faq/index.vue')
  },
  {
    path: '/pass/register',
    name: 'register',
    component: () => import('../views/pass/register/index.vue')
  },
  {
    path: '/pass/login',
    name: 'login',
    component: () => import('../views/pass/login/index.vue')
  },
  {
    path: '/pass/reset',
    name: 'reset',
    component: () => import('../views/pass/reset/index.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/detail/index.vue')
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: () => import('../views/Introduction/index.vue')
  },
  {
    path: '/help/notice',
    name: 'notice',
    component: () => import('../views/help/notice/index.vue')
  },
  {
    path: '/api/loginCallback/google',
    name: 'google',
    component: () => import('../views/api/loginCallback/google.vue')
  },
  // 重定向
  {
    path: '*',
    redirect: '/'
  }

]

const router = new VueRouter({
  // mode: 'hash',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 监听路由变化
router.afterEach((to, from) => {
  window.scrollTo(0, 0)
})

// let whiteList = ['login', 'register', 'reset']

export default router
