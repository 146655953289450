import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import VueI18n from 'vue-i18n';
import echarts from './utils/echarts.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/el-element.css'
import i18n from './common/lang';
// import enLocale from 'element-ui/lib/locale/lang/en';
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false
/** 国际化 **/
// Vue.use(VueI18n);
// Vue.use(ElementUI, {
//   i18n: (key, value) => i18n.t(key, value)
// });
// const i18n = new VueI18n({
//   locale: 'en',
//   messages: {
//     'zh': Object.assign(require('@/common/lang/zh'), zhLocale),
//     'en': Object.assign(require('@/common/lang/en'), enLocale),
//   }
// });

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')