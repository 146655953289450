import Cookies from 'js-cookie'

// token
const TokenKey = 'Token'

export function getToken() {
  // return Cookies.get(TokenKey)
  return localStorage.getItem('token')||'';
}

export function getVirtualStatus() {
  return localStorage.getItem('virtualStatus') == 1 ? 'NO' : 'YES';
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}


export function removeToken() {
  return Cookies.remove(TokenKey)
}

// userinfo
const userName = 'userName'

export function getUserName() {
    return Cookies.get(userName)
}

export function setUserName(user) {
    return Cookies.set(userName, user)
}

export function removeUserName() {
    return Cookies.remove(userName)
}