<template>
  <div>
    <nav class="nav_pc">
      <div class="nav_content">
        <div class="nav_left">
          <div class="logo">
            <img src="@/assets/img/sax_logo.png" alt="" /><span>{{ title }}</span>
          </div>
          <div class="nav_tab_content" v-if="!isShowNav">
            <div class="nav_item cur" v-for="(item, index) in navList" :key="index" :class="currentPath == item.path ? 'active' : ''" @click="gopage(item.path)">{{ item.name }}</div>
            <!-- <div class="nav_item cur" :class="currentPath == '/market' ? 'active' : ''" @click="gopage('/market')">市场
            </div>
            <div class="nav_item cur" @click="gopage('/trading')" :class="currentPath == '/trading' ? 'active' : ''">智能交易
            </div>
            <div class="nav_item cur" @click="gopage('/loan')" :class="currentPath == '/loan' ? 'active' : ''">贷款</div>
            <div class="nav_item cur" @click="gopage('/nfts')" :class="currentPath == '/nfts' ? 'active' : ''">NFTs</div>
            <div class="nav_item cur" @click="gopage('/profile')" :class="currentPath == '/profile' ? 'active' : ''">账户
            </div> -->
          </div>
        </div>
        <div class="nav_right">
          <div class="service" @click="openService" >
            <img src="@/assets/img/service.svg" :alt="$t('navBottom.bottomCompanyItem4')">
          </div>
          
          <div class="lang finger-dark" id="myModal">
            <div class="lang_content cur">
              <span @click="openLanguage()">{{ $t('navTab.language') }}</span>
              <img :class="isLanguage ? 'active' : ''" src="@/assets/img/icon_arrow.dc4b9070.svg"
                alt="" />
            </div>
            <div class="select_container" v-if="isLanguage">
              <div class="s_title">{{ $t('navTab.languageTips') }}</div>
              <div class="s_options">
                <div class="section_option">
                  <div class="option_item" @click="langChange(index)" v-for="(item, index) in langList" :key="index">
                    <span class="item_name" :class="{ 'active': langIndex == index }">{{ item.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="actions_content" v-if="!isLogin && !isShowNav">
            <div class="action" @click="$router.push('/pass/login')">{{ $t('navTab.btnLogin') }}</div>
            <div class="action sign finger-dark" @click="$router.push('/pass/register')">{{ $t('navTab.btnSignup') }}</div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapState,mapGetters } from "vuex";
export default {
  name: "HomeView",
  data() {
    return {
      isLanguage: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "Galaxy Digital",
    }
  },
  computed: {
    ...mapState(["footerWhiteList"]),
    ...mapGetters(["langList","langIndex"]),
  
    isLogin() {
      return this.$store.state.isLogin;
    },
    currentRouteName() {
      return this.$route.name;
    },
    isShowNav() {
      return this.footerWhiteList.includes(this.currentRouteName);
    },
    navList() {
      return [
        {
          name: this.$t('navTab.tabMenu1'),
          path: '/'
        },
        {
          name: this.$t('navTab.tabMenu2'),
          path: '/market'
        },
        {
          name:  this.$t('navTab.tabMenu3'),
          path: '/trading'
        },
        {
          name: this.$t('navTab.tabMenu4'),
          path: '/loan'
        },
        {
          name: this.$t('navTab.tabMenu5'),
          path: '/nfts'
        },
        {
          name: this.$t('navTab.tabMenu6'),
          path: '/profile'
        },
      ]
    },
    // 获取当前路由路径
    currentPath() {
      console.log(this.$route);
      // 获取子路由之前的路径 /profile/balance 取/profile
      let path = '/' + this.$route.path.split('/')[1];
      return path
    },
  },
  mounted() {
    this.$nextTick(() => {
      let that=this;
      var modal=document.getElementById('myModal');
      // 当用户点击页面上的任意位置时，检查点击的位置
      document.addEventListener('click', function (event) {
        // 如果点击的位置不在模态框内，关闭模态框
        if (event.target!==modal&&!modal.contains(event.target)) {
          that.closeModal();
        }
      });
    });
    // this.langIndex = this.langList.findIndex((item) => item.value === this.$store.state.currentLanguage);
  },

  methods: {
    openService() {
      let origin=window.location.origin;
      window.open(origin+'/service.html?language=en');
    },
    langChange(index) {
      // this.langIndex=index;
      let lang=this.langList[index].value;
      this.closeModal();
      this.$store.commit('SET_LANG', lang);
      this.$store.dispatch('listContents', lang == 'zh' ? 1 : 2);
    },
    closeModal() {
      this.isLanguage=false;
    },
    openLanguage() {
      this.isLanguage=!this.isLanguage;
    },
    gopage(url) {
      this.$router.push(
        {
          path: url,
        },
        () => { }
      );
    },
  },
};
</script>
<style scoped>
.nav_pc {
  padding-top: 0.32rem;
  height: 0.44rem;
  min-width: 11rem;
}

.nav_pc .nav_content {
  max-width: 14.40rem;
  width: 100%;
  padding: 0 0.6rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.nav_pc .nav_content .nav_left {
  display: flex;
  align-items: center;
}

.nav_pc .nav_content .nav_left .logo {
  margin-right: 0.6rem;
  height: 0.44rem;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 0.24rem;
  color: #000;
}

.nav_pc .nav_content .nav_left .logo span {
  /* padding-bottom: 0.04rem; */
}

.nav_pc .nav_content .nav_left .logo img {
  margin-right: 0.08rem;
  height: 100%;
  width: auto;
  /* border-radius: 50%; */

}

.nav_pc .nav_content .nav_left .nav_tab_content {
  display: flex;
  align-items: center;
}

.nav_pc .nav_content .nav_left .nav_tab_content .nav_item {
  margin-right: 0.34rem;
  font-size: 0.16rem;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.nav_pc .nav_content .nav_left .nav_tab_content .nav_item.active,
.nav_pc .nav_content .nav_left .nav_tab_content .nav_item:hover {
  color: #000;
}

.nav_pc .nav_content .nav_right {
  display: flex;
  align-items: center;

}
.service{
  margin-right: 20px;
  
}
.service img{
  width: 20px;
}

.nav_pc .nav_content .nav_right .lang {
  position: relative;
  margin-right: 0.2rem;
  display: flex;
  align-items: center;
}

.nav_pc .nav_content .nav_right .lang .lang_content {
  display: flex;
  align-items: center;
  font-size: 0.16rem;
}

.nav_pc .nav_content .nav_right .lang .lang_content img {
  margin-top: 0.04rem;
  margin-left: 0.1rem;
  width: 0.08rem;
  height: auto;
}

.nav_pc .nav_content .nav_right .lang .lang_content img.active {
  transform: rotate(180deg);
}

.nav_pc .nav_content .nav_right .lang .select_container {
  cursor: default;
  padding: 0.2rem;
  position: absolute;
  right: 0;
  top: 0.54rem;
  background: #fff;
  box-shadow: 0 0.34rem 0.84rem rgba(0, 0, 0, 0.1);
  border-radius: 0.1rem;
  box-sizing: border-box;
  z-index: 999;
}

.nav_pc .nav_content .nav_right .lang .select_container .s_title {
  font-weight: 500;
  font-size: 0.16rem;
  color: #000;
}

.nav_pc .nav_content .nav_right .lang .select_container .s_options {
  margin-top: 0.2rem;
  font-weight: 500;
  font-size: 0.14rem;
  color: #000;
}

.nav_pc .nav_content .nav_right .lang .select_container .s_options .section_option {
  /* display: flex;
  justify-content: space-between; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.nav_pc .nav_content .nav_right .lang .select_container .s_options .section_option .option_item {
  margin-bottom: 0.14rem;
  min-width: 1.86rem;
  width: auto;
  white-space: nowrap;
}

.nav_pc .nav_content .nav_right .lang .select_container .s_options .section_option .option_item:nth-child(odd) {
  margin-right: 0.45rem;
}

.nav_pc .nav_content .nav_right .lang .select_container .s_options .section_option .option_item .item_name {
  cursor: pointer;
  display: inline-block;
  padding: 0.1rem 0.22rem;
}

.nav_pc .nav_content .nav_right .lang .select_container .s_options .section_option .option_item .item_name.active {
  background: linear-gradient(180deg, #ffdc61, #ffbc49);
  border-radius: 0.24rem;
}

.nav_pc .nav_content .nav_right .actions_content {
  display: flex;
}

.nav_pc .nav_content .nav_right .actions_content .action {
  margin-left: 0.12rem;
  padding: 0 0.24rem;
  height: 0.42rem;
  border: 0.01rem solid #000;
  box-sizing: border-box;
  border-radius: 0.24rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav_pc .nav_content .nav_right .actions_content .action.sign {
  border: none;
  background: linear-gradient(180deg, #ffdc61, #ffbc49);
}

.input_fields {
  position: relative;
  width: 100%;
  height: 0.68rem;
  box-sizing: border-box;
  border: 0.02rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
}

.input_fields .input {
  width: 100%;
  height: 100%;
  border-radius: 0.2rem;
  box-sizing: border-box;
  padding: 0 0.28rem;
  border: unset;
  outline: none;
  color: #000;
  font-weight: 500;
  caret-color: #000;
}

.input_fields .input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.input_fields .input:disabled {
  color: rgba(0, 0, 0, 0.5);
}

.input_fields .label_name {
  padding: 0 0.06rem;
  position: absolute;
  top: -0.15rem;
  left: 0.26rem;
  font-weight: 500;
  font-size: 0.18rem;
  color: rgba(0, 0, 0, 0.5);
  background: #fff;
}

.input_fields.active {
  border: 0.02rem solid #000;
}

.input_fields.active .label_name {
  color: #000;
}

.input_fields[data-v-b9143b16] {
  padding-right: 0.08rem;
  position: relative;
  width: 100%;
  height: 0.68rem;
  box-sizing: border-box;
  border: 0.02rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input_fields .input[data-v-b9143b16] {
  flex: 1;
  height: 100%;
  border-radius: 0.2rem;
  box-sizing: border-box;
  padding: 0 0.28rem;
  border: unset;
  outline: none;
  color: #000;
  font-weight: 500;
  caret-color: #000;
}

.input_fields .input[data-v-b9143b16]::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.input_fields .input[data-v-b9143b16]:disabled {
  color: rgba(0, 0, 0, 0.5);
}

.input_fields .action[data-v-b9143b16] {
  cursor: pointer;
  height: 0.52rem;
  min-width: 0.5rem;
  line-height: 0.52rem;
  background: linear-gradient(180deg, #ffdc61, #ffbc49);
  color: #000;
  padding: 0 0.2rem;
  border-radius: 0.2rem;
  font-size: 0.18rem;
  text-align: center;
}

.input_fields .action.black[data-v-b9143b16] {
  background: #000;
  color: #fff;
}

.input_fields .action.loading[data-v-b9143b16] {
  background: #e8e8e8;
  color: #999;
}

.input_fields .label_name[data-v-b9143b16] {
  padding: 0 0.06rem;
  position: absolute;
  top: -0.15rem;
  left: 0.26rem;
  font-weight: 500;
  font-size: 0.18rem;
  color: rgba(0, 0, 0, 0.5);
  background: #fff;
}

.input_fields.active[data-v-b9143b16] {
  border: 0.02rem solid #000;
}

.input_fields.active .label_name[data-v-b9143b16] {
  color: #000;
}

.pass_wrapper .nav_mobile {
  padding-top: 0.22rem;
}

.pass_wrapper .nav_mobile .nav_content {
  padding: 0 0.24rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pass_wrapper .nav_mobile .nav_content .logo {
  height: 0.36rem;
  display: flex;
  align-items: center;
}

.pass_wrapper .nav_mobile .nav_content .logo img {
  height: 100%;
  width: auto;
}

.pass_wrapper .nav_mobile .nav_content .logo .nav_name {
  margin-left: 0.1rem;
  /* padding-bottom: 0.04rem; */
  font-weight: 700;
  font-size: 0.22rem;
  color: #000;
}

.pass_wrapper .nav_mobile .nav_content .line {
  flex: 1;
  margin: 0 0.16rem;
  height: 0.02rem;
  background: #000;
}

.pass_wrapper .nav_mobile .nav_content .sign_content {
  display: flex;
}

.pass_wrapper .nav_mobile .nav_content .sign_content .sign_item {
  box-sizing: border-box;
  width: 0.14rem;
  height: 0.14rem;
  background: #fff;
  border: 0.02rem solid #000;
  border-radius: 50%;
}

.pass_wrapper .nav_mobile .nav_content .sign_content .sign_item:last-child {
  margin-left: 0.06rem;
}

.pass_wrapper .nav_mobile .nav_content .sign_content .sign_item.active {
  background: #000;
}

.pass_wrapper .pass_container {
  margin-top: 0.75rem;
  display: flex;
  justify-content: center;
}

.pass_wrapper .pass_container.mobile {
  margin-top: 0.5rem;
  padding: 0 0.24rem;
}

.pass_wrapper .pass_container .pass_left.pc {
  width: 5rem;
}

.pass_wrapper .pass_container .pass_left.mobile {
  width: 100%;
}

.pass_wrapper .pass_container .pass_left .wel_title {
  margin-bottom: 0.44rem;
  font-weight: 700;
  font-size: 0.38rem;
  color: #000;
}

.pass_wrapper .pass_container .pass_left .form_content {
  margin-bottom: 0.26rem;
}

.pass_wrapper .pass_container .pass_left .form_content .input_content {
  margin-top: 0.22rem;
}

.pass_wrapper .pass_container .pass_left .actions_content {
  margin-bottom: 0.36rem;
}

.pass_wrapper .pass_container .pass_left .actions_content .button_content {
  margin-top: 0.14rem;
  height: 0.64rem;
}

.pass_wrapper .pass_container .pass_left .type_content {
  font-size: 0.22rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

.pass_wrapper .pass_container .pass_left .type_content.mobile {
  padding-bottom: 0.5rem;
  font-size: 0.18rem;
  text-align: center;
}

.pass_wrapper .pass_container .pass_left .type_content span {
  cursor: pointer;
  color: #000;
}

.pass_wrapper .pass_container .pass_right {
  margin-left: 0.8rem;
  width: 4.64rem;
}

.pass_wrapper .pass_container .pass_right .img_content {
  text-align: center;
}

.pass_wrapper .pass_container .pass_right .img_content img {
  height: auto;
}

.pass_wrapper .pass_container .pass_right .img_content img.register {
  width: 3.1rem;
}

.pass_wrapper .pass_container .pass_right .img_content img.login {
  width: 3.5rem;
}

.pass_wrapper .pass_container .pass_right .intro_content {
  margin-top: 0.28rem;
  text-align: center;
}

.pass_wrapper .pass_container .pass_right .intro_content .title {
  width: 3.27rem;
  margin: 0 auto;
  font-weight: 700;
  font-size: 0.3rem;
}

.pass_wrapper .pass_container .pass_right .intro_content .content {
  margin-top: 0.22rem;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.18rem;
}

.pass_wrapper .tips_content {
  margin-top: 1.06rem;
  padding-bottom: 0.4rem;
  text-align: center;
  font-size: 0.2rem;
  color: rgba(0, 0, 0, 0.5);
}

.pass_wrapper .tips_content.mobile {
  margin-top: 0;
  font-size: 0.14rem;
}
</style>