<template>
  <div>
    <nav class="nav_bottom">
      <div class="nav_content">
        <div class="content_top">
          <div class="top_left">
            <div class="logo">
              <img src="@/assets/img/sax_logo.png" alt="" />
              <div class="nav_name">Galaxy Digital</div>
            </div>
            <div class="menu_section">
              <div class="menu_item first">{{ $t('navBottom.bottomProduct') }}</div>
              <div class="menu_item" @click="$router.push('/market')">{{ $t('navBottom.bottomProductItem1') }}</div>
              <div class="menu_item" @click="$router.push('/loan')">{{ $t('navBottom.bottomProductItem2') }}</div>
              <div class="menu_item" @click="$router.push('/nfts')">{{ $t('navBottom.bottomProductItem3') }}</div>
              <div class="menu_item" @click="$router.push('/trading')">{{ $t('navBottom.bottomProductItem4') }}</div>
            </div>
            <div class="menu_section">
              <div class="menu_item first">{{ $t('navBottom.bottomCompany') }}</div>
              <div class="menu_item" v-for="(item, index) in company" @click="toDetail(item)" :key="index">{{ item.title
              }}</div>
              <!-- <div class="menu_item">{{ $t('navBottom.bottomCompanyItem2') }}</div>
              <div class="menu_item">{{ $t('navBottom.bottomCompanyItem3') }}</div>
              <div class="menu_item">{{ $t('navBottom.bottomCompanyItem4') }}</div> -->
            </div>
            <div class="menu_section">
              <div class="menu_item first">{{ $t('navBottom.bottomPolicy') }}</div>
              <div class="menu_item" v-for="(item, index) in policy" @click="toDetail(item)" :key="index">{{ item.title }}
              </div>
              <!-- <div class="menu_item">{{ $t('navBottom.bottomPolicyItem2') }}</div> -->
            </div>
          </div>
          <div class="top_right">
            <div class="icons">
              <div class="icon">
                <img src="@/assets/img/icon_discord.08727466.svg" alt="" />
              </div>
              <div class="icon">
                <img src="@/assets/img/icon_twitter.667925f3.svg" alt="" />
              </div>
              <div class="icon">
                <img src="@/assets/img/icon_telegram.83daadb6.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="content_bottom">
          <div class="bottom_left">
            <div class="link_menu" v-for="(item, index) in footer" @click="toDetail(item)" :key="index">{{ item.title }}
            </div>
            <!-- <div class="link_menu">{{ $t('navBottom.bottomOtherItem1') }}</div> -->
            <!-- <div class="link_menu">{{ $t('navBottom.bottomOtherItem3') }}</div>
            <div class="link_menu">{{ $t('navBottom.bottomOtherItem4') }}</div>
            <div class="link_menu">{{ $t('navBottom.bottomOtherItem5') }}</div> -->
          </div>
          <div class="bottom_right">© 2023 Galaxy Digital. All rights reserved</div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "NavBottomPc",
  computed: {
    ...mapGetters(["getContentList"])
  },
  watch: {
    getContentList: {
      handler(val) {
        this.company=val.filter((item) => item.type==1);
        this.policy=val.filter((item) => item.type==2);
        this.footer=val.filter((item) => item.type==3);
      },
      immediate: true,
      deep: true,
    }
  },
  data() {
    return {
      // 公司
      company: [],
      policy: [],
      footer: []
    };
  },
  methods: {
    toDetail(item) {
      // 判断跳转的路由是否是当前路由
      if (this.$route.query.id == item.id) {
        return;
      }
      
      this.$router.push({ path: "/detail", query: { id: item.id, title: item.title } });
    }

  },
  mounted() { }
};
</script>
<style>
.nav_bottom {
  padding: 0.14rem;
  min-width: 11rem;
  max-width: 1440px;
  margin: 0 auto;
}

.nav_bottom .nav_content {
  padding: 0.7rem;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.14rem;
  background: #000;
}

.nav_bottom .nav_content .content_top {
  display: flex;
  justify-content: space-between;
}

.nav_bottom .nav_content .content_top .top_left {
  display: flex;
}

.nav_bottom .nav_content .content_top .top_left .logo {
  margin-right: 1.27rem;
  height: 0.32rem;
  display: flex;
  align-items: center;
}

.nav_bottom .nav_content .content_top .top_left .logo img {
  height: 100%;
  width: auto;
}

.nav_bottom .nav_content .content_top .top_left .logo .nav_name {
  margin-left: 0.1rem;
  /* padding-bottom: 0.04rem; */
  font-weight: 700;
  font-size: 0.22rem;
  color: #fff;
}

.nav_bottom .nav_content .content_top .top_left .menu_section {
  margin-left: 0.97rem;
}

.nav_bottom .nav_content .content_top .top_left .menu_section .menu_item {
  margin-top: 0.2rem;
  font-size: 0.16rem;
  color: hsla(0, 0%, 100%, 0.6);
}

.nav_bottom .nav_content .content_top .top_left .menu_section .menu_item:hover {
  cursor: pointer;
  color: #fff;
}

.nav_bottom .nav_content .content_top .top_left .menu_section .menu_item.first {
  cursor: default;
  margin-top: 0;
  margin-bottom: 0.3rem;
  color: #fff;
  font-size: 0.18rem;
}

.nav_bottom .nav_content .content_top .top_right .icons {
  display: flex;
}

.nav_bottom .nav_content .content_top .top_right .icons .icon {
  margin-left: 0.12rem;
  width: 0.44rem;
  height: 0.44rem;
  border-radius: 50%;
  background: hsla(0, 0%, 100%, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav_bottom .nav_content .content_top .top_right .icons .icon img {
  height: 0.16rem;
  width: auto;
}

.nav_bottom .nav_content .content_bottom {
  margin-top: 0.7rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.14rem;
}

.nav_bottom .nav_content .content_bottom .bottom_left {
  display: flex;
  color: hsla(0, 0%, 100%, 0.6);
}

.nav_bottom .nav_content .content_bottom .bottom_left .link_menu {
  margin-right: 0.58rem;
}

.nav_bottom .nav_content .content_bottom .bottom_left .link_menu:hover {
  cursor: pointer;
  color: #fff;
}

.nav_bottom .nav_content .content_bottom .bottom_left .link_menu:last-child {
  margin-right: 0;
}

.nav_bottom .nav_content .content_bottom .bottom_right {
  color: #fff;
}

.layout[data-v-06f2876c] {
  width: 100%;
}</style>