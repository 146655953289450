import request from '@/utils/request'


// 市场行情列表
export function listMarketByType(param) {
    return request({
        url: `/swx/customer/index/listMarketByType`,
        method: 'get',
        params: {
            ...param
        }
    })
}

// k线图
export function getKValueByCodeAndDate(param) {
    return request({
        url: `/swx/customer/index/getKValueByCodeAndDate`,
        method: 'get',
        params: {
            ...param
        }
    })
}
// getMarketInfo
export function getMarketInfo(id) {
    return request({
        url: `/swx/customer/index/getMarketInfo/${id}`,
        method: 'get',
        // params: {
        //     ...param
        // }
    })
}
// listContents
export function listContents(lang) {
    return request({
        url: `/swx/customer/index/listContents/${lang}`,
        method: 'get',
    })
}
// /getContentsById/{id}
export function getContentsById(id) {
    return request({
        url: `/swx/customer/index/getContentsById/${id}`,
        method: 'get',
    })
}

// /getParamByKey/{key}
export function getParamByKey(key) {
    // withdrawal_fee 提现手续费
    // recharge_account 充值账号


    return request({
        url: `/swx/customer/index/getParamByKey/${key}`,
        method: 'get',
    })
}

// listNft
export function listNft(param) {
    return request({
        url: `/swx/customer/index/listNft`,
        method: 'get',
        params: {
            ...param
        }
    })
}

// buySmart
export function buySmart(data) {
    return request({
        url: `/swx/customer/order/buySmart`,
        method: 'post',
        data
    })
}
// listSmartOrder
export function listSmartOrder(param) {
    return request({
        url: `/swx/customer/order/listSmartOrder`,
        method: 'get',
        params: {
            ...param
        }
    })
}

// buyOptions
export function buyOptions(data) {
    return request({
        url: `/swx/customer/order/buyOptions`,
        method: 'post',
        data
    })
}

export function getOptionsOrderById(id) {
    return request({
        url: `/swx/customer/order/getOptionsOrderById/${id}`,
        method: 'get',
    })
}
// listOptionsOrder
export function listOptionsOrder(param) {
    return request({
        url: `/swx/customer/order/listOptionsOrder`,
        method: 'get',
        params: {
            ...param
        }
    })
}


